const For = {}

const language = document.documentElement.lang;

/* GENERAL QUERY STRUCTURE FOR IMAGES */
For.Images = {
  query: 'page.images',
  select: {
    id: true,
    filename: true,
    url: true,
    width: 'file.width()',
    height: 'file.height()',
    ratio: 'file.ratio()',
    srcset: 'file.srcset([250, 450, 800, 1440])',
    /* webp: 'file.srcset("webp")', */
    /* avif: 'file.srcset("avif")' */
  }
};

/* QUERY STRUCTURE FOR DOCUMENTS */
For.Files = {
  query: 'page.files',
  select: {
    id: true,
    filename: true,
    url: true
  }
};

/* BASE FIELDS FOR PAGES */
const Base = {
  title: language ? 'page.content("' + language + '").title' : true,
  content: language ? 'page.content("' + language + '")' : true,
  url: language ? 'page.url("' + language + '")' : true,
  id: true,
  parent: true,
  //uuid: 'page.uuid',
  uid: true,
  template: 'page.intendedTemplate()',
  listed: 'page.isListed',
  images: For.Images,
  files: For.Files,
};

/* GENERAL QUERY STRUCTURE FOR CHILDREN */
For.Children = {
  ...Base,
  children: {
    query: 'page.children',
    select: {
      ...Base,
    }
  }
};

/* PAGES */
/* ===== */

For.Default = {
  ...Base,
  text: language ? 'page.content("' + language + '").text.kirbytext' : 'page.text.kirbytext',
}

For.Home = {
  ...Base,
  featured: true,
  more: true,
}

For.Site = {
  content: language ? 'site.content("' + language + '")' : true,
  footer: 'site.content.footer.kirbytext'
}

export default {
  For
}
