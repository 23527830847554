export const distort = (x, y, width, height, time = 1, blend = 0.5) => {
  const a = distortA(x, y, width, height, time);
  const b = distortB(x, y, width, height);
  const c = {x, y}
  return interpolateDistortions(a, c, blend);
};

export const interpolateDistortions = (a, b, blend) => {
  return {
    x: a.x * (1 - blend) + b.x * blend,
    y: a.y * (1 - blend) + b.y * blend
  };
};

export const distortA = (x, y, width, height, time = 1, inside = 0.6) => {
  if (x && y) {
    const pushFactor = 2;
    const normalx = x / width;
    const normaly = y / height;

    const centerX = 0.5;
    const centerY = 0.5;

    const dx = normalx - centerX;
    const dy = normaly - centerY;
    const dd = Math.sqrt(dx * dx + dy * dy);
    const cycle = Math.sin(time*-0.05*dd)*0.4;
    const distDelta = inside + 0.075 * cycle - dd;

    const bentNormalX = (dx / dd) * distDelta;
    const bentNormalY = (dy / dd) * distDelta;

    return { x: x + bentNormalX * width, y: y + bentNormalY * height };
  } else {
    return { x, y };
  }
};

export const distortRainbow = (x, y, inside = 0.5, edge = 0.5) => ({
  x: (0.5 + (edge - inside * y) * Math.cos(Math.PI * (1 - x))) - x,
  y: (0.5 - (edge - inside * y) * Math.sin(Math.PI * (1 - x))) - y
});

export const distortB = (x, y, width, height) => {
  if (x && y) {
    const normalX = x / width;
    const normalY = y / height;
    const distortXY = {
      x: distortRainbow(normalX, normalY).x * width,
      y: distortRainbow(normalX, normalY).y * height
    };
    return { x: x + distortXY.x, y: y + distortXY.y };
  } else {
    return { x, y };
  }
};
